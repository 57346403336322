import * as React from 'react'
import '../stylesheets/global.scss'
import { Col, Container, Row } from 'react-bootstrap'
import TruckService from '../images/truck_service.jpg'
import Logistics from '../images/logistics.jpg'
import * as styles from './index.module.scss'
import Button, { ButtonTypes } from '../components/Elements/Button/Button'
import AboutUs from '../components/AboutUs/AboutUs'
import Seo from '../components/Seo/Seo'

interface Props {
	location: Location
}

export default ({ location }: Props) => (
	<div>
		<Seo title='Pagrindinis' pathName={location.pathname} />
		<Container fluid>
			<div className={styles.header}>
				<Row>
					<Col lg={6} md={12} sm={12} xs={12} className='pr-0 pl-0'>
						<figure className={styles.figure}>
							<div
								className={styles.figureBackground}
								style={{ backgroundImage: `url(${TruckService})` }}
							>
								<figcaption>
									<div className={styles.figcaptionBody}>
										<h2>Serviso paslaugos</h2>
										<span>
											<Button buttonType={ButtonTypes.BUTTON} url='/service' title='Plačiau' />
										</span>
									</div>
								</figcaption>
							</div>
						</figure>
					</Col>
					<Col lg={6} md={12} sm={12} xs={12} className='pr-0 pl-0'>
						<figure className={styles.figure}>
							<div
								className={styles.figureBackground}
								style={{ backgroundImage: `url(${Logistics})` }}
							>
								<figcaption>
									<div className={styles.figcaptionBody}>
										<h2>Logistikos Paslaugos</h2>
										<span>
											<Button buttonType={ButtonTypes.BUTTON} url='/logistics' title='Plačiau' />
										</span>
									</div>
								</figcaption>
							</div>
						</figure>
					</Col>
				</Row>
			</div>
		</Container>
		<Container className='mt-5'>
			<AboutUs />
		</Container>
	</div>
)
