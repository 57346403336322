import * as React from 'react'
import { FaCheck } from 'react-icons/all'
import SectionHeading from '../SectionHeading/SectionHeading'
import * as styles from './AboutUs.module.scss'

export default () => {
	return (
		<div>
			<SectionHeading title='Apie mus' />
			<div className={styles.container}>
				<p>
					Mūsų patirtis suteikė galimybę pažvelgti plačiau į transporto ir logistikos sferą. Spektro
					servisas ne tik pasirūpins Jūsų krovinių transportavimu iš taško A į tašką B, bet taip pat
					pasirūpins Jūsų transporto priemonės priežiūra ir taisymu. Mes Jums galime pasiūlyti
					sunkiujų transpoto priemonių, priekabų, puspriekabių bei bei cisternų taisyma ir didelį
					asortimentą atsarginių dalių.
				</p>
				<table className={styles.table}>
					<tbody>
						<tr>
							<td>
								<span>
									<FaCheck />
								</span>
							</td>
							<td>
								Daugiau nei <b>15 metų patirtis</b>
							</td>
						</tr>
						<tr>
							<td>
								<span>
									<FaCheck />
								</span>
							</td>
							<td>
								Mus rasite vos <b>20 minučių nuo centro</b>
							</td>
						</tr>
						<tr>
							<td>
								<span>
									<FaCheck />
								</span>
							</td>
							<td>
								Męs siūlome <b>konkurencinga kaina</b>
							</td>
						</tr>
						<tr>
							<td>
								<span>
									<FaCheck />
								</span>
							</td>
							<td>
								Draugiškas ir <b>profesionalus servisas</b>
							</td>
						</tr>
						<tr>
							<td>
								<span>
									<FaCheck />
								</span>
							</td>
							<td>
								Dirbame <b>6 dienas per savaite</b>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}
