import React from 'react'
import { Link } from 'gatsby'
import * as styles from './Button.module.scss'

interface ButtonProps {
	buttonType: ButtonTypes
	title: string
	url: string
	newTab?: boolean
}

export enum ButtonTypes {
	LINK,
	BUTTON
}

export default ({ buttonType = ButtonTypes.BUTTON, title, url, newTab = false }: ButtonProps) => {
	const getButtonStyle = () => {
		switch (buttonType) {
			case ButtonTypes.LINK:
				return styles.link
			case ButtonTypes.BUTTON:
			default:
				return styles.button
		}
	}

	if (newTab) {
		return (
			<a href={url} target='_blank' rel='noopener noreferrer' className={getButtonStyle()}>
				{title}
			</a>
		)
	}
	return (
		<Link to={url} className={getButtonStyle()}>
			{title}
		</Link>
	)
}
